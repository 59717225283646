/**
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：計算式
親子関係：
更新者：
①
更新内容：
①
**/
import newBuildingFunction from './newBuildingFunction.js';
export default {
  mixins: [newBuildingFunction],
  data: function() {
    return {
      magnification: 1.1,
      leverage: 1.2,
    };
  },
  computed: {
    // 築年数
    ageOfBuilding: function() {
      return function(id) {
        try {
          let inputData = this.investmentAnalytics.inputDate.split('-');
          let constructionData = this.investmentAnalytics.initialInvestment.propertyDescription[
            id
          ].dateOfConstruction.split('-');
          let d1 = new Date(inputData[0], inputData[1], inputData[2]);
          let d2 = new Date(constructionData[0], constructionData[1], constructionData[2]);
          let diffMilliSec = d1 - d2;
          // 年数計算
          return Math.max(0, Math.floor(diffMilliSec / 1000 / 60 / 60 / 24 / 365));
        } catch (e) {
          return null;
        }
      };
    },
    // 本体価格 既存建物合計
    tradingPriceBuilding: function() {
      let total = 0;
      // 既存建物
      for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
        total += Number(this.investmentAnalytics.initialInvestment.tradingPrice[i]);
      }
      // 付属建物
      if (!isNaN(Number(this.investmentAnalytics.initialInvestment.tradingPrice[21]))) {
        total += Number(this.investmentAnalytics.initialInvestment.tradingPrice[21]);
      }
      // 合計を返す
      return total;
    },
    // 売買代金 合計(税抜)
    tradingPriceTotal: function() {
      let total = 0;
      // 土地
      total += Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]);
      // 既存建物・付属建物
      total += this.tradingPriceBuilding;
      // 合計を返す
      return total;
    },
    // 本体価格 建物合計 消費税
    tradingPriceBuildingTax: function() {
      let total = 0;
      // 既存建物・付属建物
      total += this.tradingPriceBuilding;
      // 税額を返す
      return this.tax(total);
    },
    // 売買価格 建物合計 消費税
    tradingPriceTax: function() {
      let total = 0;
      // 既存建物
      total += Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.building);
      // 償却資産
      total += Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.depreciatedAssets);
      // 税額を返す
      return this.tax(total);
    },
    // 売買代金 目安
    tradingPriceGuideline: function() {
      return function(id, rollUp) {
        if (this.investmentAnalytics.initialInvestment.totalTradingPrice == null) return 0;
        let result = 0;
        // 土地
        if (id == 0) {
          // 代金総額×固定資産評価/土地 ÷ (固定資産評価/土地＋固定資産評価/既存建物の税込合計)
          let a = Number(this.investmentAnalytics.initialInvestment.totalTradingPrice);
          let b = this.investmentAnalytics.initialInvestment.propertyTaxValuation[id];
          let c = this.propertyTaxValuationTotal();
          let d = this.tax(c);
          result = Math.floor((a * b) / (b + c + d));

          //一円誤差対応が必要な場合
          if (rollUp) {
            //端数丸目処理 一円の誤差発生を無いように処理する
            let count = [];
            //既存物件を取得する
            for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
              count.push(i);
            }
            if(this.investmentAnalytics.initialInvestment.propertyTaxValuation[21]){
              count.push(21);
            }

            let sum = 0;
            for (let i of count) {
              // (代金総額－売買代金/土地)÷(1+消費税率) × 固定資産評価/既存建物 ÷ (固定資産評価/既存建物の合計)
              let a = Number(this.investmentAnalytics.initialInvestment.totalTradingPrice);
              let b = result;
              let c = 1 + this.getTax('消費税率') / 100;
              let d = this.investmentAnalytics.initialInvestment.propertyTaxValuation[i];
              let e = this.propertyTaxValuationTotal();
              sum += Math.floor(((a - b) * d) / (c * e));
              //console.log('まるめ', a, b, c, d, e);
            }
            sum += this.tax(sum); //消費税の計算
            sum += result; //土地代の計算

            //console.log('丸目前金額', sum);
            //console.log('丸目額', a - sum);
            //一円誤差の丸目設定
            if (a > sum || a < sum) {
              result += a - sum;
            }
          }
        }
        // 既存建物、付属建物
        else if (id < 11 || id == 21) {
          // (代金総額－売買代金/土地)÷(1+消費税率) × 固定資産評価/既存建物 ÷ (固定資産評価/既存建物の合計)
          let a = Number(this.investmentAnalytics.initialInvestment.totalTradingPrice);
          let b = this.tradingPriceGuideline(0);
          let c = 1 + this.getTax('消費税率') / 100;
          let d = this.investmentAnalytics.initialInvestment.propertyTaxValuation[id];
          let e = this.propertyTaxValuationTotal();
          result = Math.floor(((a - b) * d) / (c * e));
        }

        return result;
      };
    },
    // 売買代金 目安 合計
    tradingPriceGuidelineTotal: function() {
      let total = 0;
      // 土地＆既存建物
      for (let i = 0; i <= this.investmentAnalytics.existingBuildingCount; i++) {
        total += this.tradingPriceGuideline(i);
      }
      return total;
    },
    // 売買代金 目安 消費税
    tradingPriceGuidelineTax: function() {
      let total = 0;
      // 既存建物
      for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
        total += this.tradingPriceGuideline(i);
      }
      return this.tax(total);
    },
    // 固定資産評価 目安
    propertyTaxValuationGuideline: function() {
      return function(id) {
        // 土地
        if (id == 0) {
          // 固定資産税路線価×計画地面積
          return (
            (Math.floor(
              Number(this.investmentAnalytics.initialInvestment.propertyDescription[id].price)
            ) *
              Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyDescription[id].area) *
                  100
              )) /
            100
          );
        }
        // 既存建物
        else if (id < 11 || id == 21) {
          //新築建物課税標準価格認定基準表（規定値内）×延床面積×減価補正率

          //新築建物課税標準価格
          let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
            id
          ];
          if (id == 21)
            propertyDescription.address = this.investmentAnalytics.initialInvestment.propertyDescription[0].address;
          let getNewBildingData = this.getNewBuilding(propertyDescription);
          let bildingSum = 0;
          if (getNewBildingData != null)
            if (getNewBildingData.length != 0) bildingSum = parseInt(getNewBildingData[0][5]);

          //延床面積
          let area = Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[id].area *
            100 )
          );
          if (isNaN(area)) area = 0;
          //減価補正率
          var varAgeOfBuilding = parseInt(this.ageOfBuilding(id));
          // 築年数が45年を超えていた場合、45年の減価補正率を適用する
          if (this.ageOfBuilding(id)) {
            if (parseInt(this.ageOfBuilding(id)) > 45) {
              varAgeOfBuilding = 45;
            } else {
              varAgeOfBuilding = parseInt(this.ageOfBuilding(id));
            }
          } else {
            varAgeOfBuilding = null;
          }
          let genkaData = this.$store.state.master.agingDepreciationRate.find(
            val => parseInt(val[0]) == varAgeOfBuilding
          );
          let structure = this.investmentAnalytics.initialInvestment.propertyDescription[id]
            .structure;
          let genka = 0;
          if (genkaData) {
            if (structure == '木造') {
              genka = parseFloat(genkaData[1]);
            } else {
              genka = parseFloat(genkaData[2]);
            }
          }

          let sum = Math.floor(bildingSum * area * genka) / 100;
          return sum;
          // 新築建物
        } else if (id < 21) {
          //新築建物課税標準価格認定基準表（規定値内）×延床面積

          //新築建物課税標準価格
          let getNewBildingData = this.getNewBuilding(
            this.investmentAnalytics.initialInvestment.propertyDescription[id]
          );
          let bildingSum = 0;
          if (getNewBildingData != null)
            if (getNewBildingData.length != 0) bildingSum = parseInt(getNewBildingData[0][5]);

          //延床面積
          let area = Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[id].area *
            100 )
          );

          let sum = Math.floor(bildingSum * area) / 100;

          return sum;
        }
      };
    },
    // 固定資産評価 目安 既存建物 合計
    propertyTaxValuationTotal: function() {
      return function() {
        let total = 0;
        // 既存建物
        for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
          total += this.investmentAnalytics.initialInvestment.propertyTaxValuation[i];
        }
        let no21 = this.investmentAnalytics.initialInvestment.propertyTaxValuation[21];
        if (!isNaN(no21)) total += no21;
        return total;
      };
    },
    // 「建物の標準的な建築価額表」検索
    standardConstructionValue: function() {
      return function(date, structure) {
        if (date == null) {
          return null;
        }
        if (this.$store.state.master == null) {
          return null;
        }
        if (this.$store.state.master.constructionCost == null) {
          return null;
        }
        try {
          let masters = this.$store.state.master.constructionCost.filter(
            x => x[0] == date.slice(0, 4)
          );
          if (masters.length == 1) {
            const structures = [
              '木造',
              '鉄骨鉄筋コンクリート造',
              '鉄筋コンクリート造',
              '軽量鉄骨造',
              '重量鉄骨造',
            ];
            let id = structures.findIndex(item => item === structure);
            return Number(masters[0][id + 1]);
          }
        } catch (e) {
          console.error({
            date: date,
            structure: structure,
            ex: e,
          });
        }
        return null;
      };
    },
    // 固定資産税 合計 既存建物のみ
    propertyTaxTotal: function() {
      let total = 0;
      // 土地
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[0]));
      // 既存建物
      total += this.propertyTaxBuildingTotal;
      // 付属建物
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[21]));
      // 償却資産
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[22]));
      return total;
    },
    // 固定資産税 合計
    propertyTaxTotalAll: function() {
      let total = 0;
      // 土地
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[0]));
      // 既存建物 + 新規建物
      total += this.propertyTaxBuildingTotalAll;
      // 付属建物
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[21]));
      // 償却資産
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[22]));
      return total;
    },
    // 都市計画税 合計 既存建物のみ
    cityPlanningTaxTotal: function() {
      let total = 0;
      // 土地
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[0]));
      // 既存建物
      total += this.cityPlanningTaxBuildingTotal;
      // 付属建物
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[21]));
      return total;
    },
    // 都市計画税 合計
    cityPlanningTaxTotalAll: function() {
      let total = 0;
      // 土地
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[0]));
      // 既存建物 + 新規建物
      total += this.cityPlanningTaxBuildingTotalAll;
      // 付属建物
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[21]));
      return total;
    },
       // 固定資産税 既存建物のみ
       propertyTaxBuildingTotal: function() {
        let total = 0;
        // 既存建物
        for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
          total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[i]));
        }
        return total;
      },
    // 固定資産税 建物合計
    propertyTaxBuildingTotalAll: function() {
      let total = this.propertyTaxBuildingTotal;
      // 新築建物
      for (let i = 1; i <= this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[10 + i]));
      }
      return total;
    },
    // 都市計画税 既存建物のみ
    cityPlanningTaxBuildingTotal: function() {
      let total = 0;
      // 既存建物
      for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
        total += Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[i]));
      }
      return total;
      },
    // 都市計画税 建物合計
    cityPlanningTaxBuildingTotalAll: function() {
      let total = this.cityPlanningTaxBuildingTotal;
      // 新築建物
      for (let i = 1; i <= this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[10 + i])
        );
      }
      return total;
    },
    // 固定資産税 目安
    propertyTaxGuideline: function() {
      return function(id) {
        let value = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[id])
        ); // 評価額
        const desc = this.investmentAnalytics.initialInvestment.propertyDescription[id]; // 物件概要
        let rate =
          Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTaxRate) * 100) /
          100; // 税率
        // 土地の場合
        if (id == 0) {

          // 居宅・共同住宅戸数
          let count = this.allNumberOfHouses();

          // すべての建物の戸数
          let building = 0;
          // 既存建物
          for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
            let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
              i + 1
            ];
              building += Math.floor(Number(propertyDescription.quantity));
            }
          // 新築建物
          for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
            let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
              i + 11
            ];
            building += Math.floor(Number(propertyDescription.quantity));
          }

          //新築or既存建物無し
          if (building == 0) {
            rate = Math.ceil(rate * 1000) / 100000;
            //固定資産評価額 * 固定資産税率
            return Math.floor(value * rate);
          }
          // 新築or既存建物に居宅・共同住宅が１件以上
          // ※居宅・共同住宅と、他建物が混在している場合は、居宅・共同住宅の税率を優先する
          //  → 2023/10/2 菊池社長に確認済み
          else if(count > 0) {

            //土地の㎡単価 = 固定資産評価額 / 土地の面積
            let unitPrice = parseInt(value) / parseFloat(desc.area);

            //① ＝ 戸数 * 200㎡
            let one = parseInt(count) * 200;
            let apply_one = parseFloat(desc.area) < one ? parseFloat(desc.area) : one;

            //② = 土地の面積 - ①
            let two = parseFloat(desc.area) - one;
            let apply_two = two > 0 ? two : 0;

            //課税標準額A = ① * 土地の㎡単価 / 6 * 固定資産税率 (切り捨て)
            let a = Math.floor(((apply_one * unitPrice) / 6) * (rate / 100));

            //課税標準額B = ② * 土地の㎡単価 / 3 * 固定資産税率 (切り捨て)
            let b = Math.floor(((apply_two * unitPrice) / 3) * (rate / 100));

            //固定資産税 = 課税標準額A + 課税標準額B
            return a + b;
          }
          // 居宅・共同住宅以外の新築or既存建物が１件以上
          else {
            rate = Math.ceil(rate * 1000) / 100000;
            //固定資産評価額 * 0.7 * 固定資産税率
            return Math.floor(value * 0.7 * rate);
          }
        }
        // 既存建物または付属建物
        else if (id < 11 || id == 21) {
          // 固定資産評価×固定資産税率
          return Math.floor(Math.floor(value * rate) / 100);
        }
        // 新築建物
        else if (id < 21) {
          let unitPrice = this.getNewBuilding(desc);
          let result = 0;
          if (unitPrice == null || unitPrice?.length == 0) {
            return 0;
          } else if (unitPrice[0][5] === '-') {
            result =
              this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[
                id - 11
              ] * 0.8;
          } else {
            // 延床面積×新築建物課税標準価格×税率
            result = (value * rate) / 100;
          }

          // 戸建て住宅・マンション等
          if (desc.kind == '共同住宅' || desc.kind == '居宅') {
            // 【新築住宅に係る税額の減額措置】
            // https://www.mlit.go.jp/jutakukentiku/house/jutakukentiku_house_tk2_000021.html
            result = result / 2; // ２分の１に減額
          }
          return Math.floor(result);
        }
        // 償却資産
        else {
          // 評価額
          const value = Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[22]);
          // 固定資産評価×固定資産税率
          return Math.floor((value * rate) / 100);
        }
      };
    },
    // 都市計画税 目安
    cityPlanningTaxGuideline: function() {
      return function(id) {
        // 評価額
        let value = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[id])
        );
        // 物件概要
        let desc = this.investmentAnalytics.initialInvestment.propertyDescription[id];
        // 税率
        let rate =
          Math.floor(Number(this.investmentAnalytics.initialInvestment.cityPlanningTaxRate) * 100) /
          100;

        // 土地の場合
        if (id == 0) {
          // 居宅・共同住宅戸数
          const count = this.allNumberOfHouses();

          // すべての建物の戸数
          let building = 0;
          // 既存建物
          for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
            let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
              i + 1
            ];
              building += Math.floor(Number(propertyDescription.quantity));
            }
          // 新築建物
          for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
            let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
              i + 11
            ];
            building += Math.floor(Number(propertyDescription.quantity));
          }

          // 新築or既存建物無し
          if (building == 0) {
            rate = Math.ceil(rate * 1000) / 100000;
            //固定資産評価額 * 都市計画税率
            return Math.floor(value * rate);
          }

          // 新築or既存建物に居宅・共同住宅が１件以上
          // ※居宅・共同住宅と、他建物が混在している場合は、居宅・共同住宅の税率を優先する
          //  → 2023/10/2 菊池社長に確認済み
          else if(count > 0) {

            //土地の㎡単価 = 固定資産評価額 / 土地の面積
            let unitPrice = parseInt(value) / parseFloat(desc.area);

            //① ＝ 戸数 * 200㎡
            let one = parseInt(count) * 200;
            let apply_one = parseFloat(desc.area) < one ? parseFloat(desc.area) : one;
            //② = 土地の面積 - ①
            let two = parseFloat(desc.area) - one;
            let apply_two = two > 0 ? two : 0;

            //課税標準額C = ① * 土地の㎡単価 / 3 * 都市計画税率 (切り捨て)
            let c = Math.floor(((apply_one * unitPrice) / 3) * (rate / 100));

            //課税標準額D = ② * 土地の㎡単価 * 2 / 3 * 都市計画税率 (切り捨て)
            let d = Math.floor(((apply_two * unitPrice * 2) / 3) * (rate / 100));

            //都市計画税 = 課税標準額C + 課税標準額D
            return c + d;
          }
          // 居宅・共同住宅以外の新築or既存建物が１件以上
          else {
            rate = Math.ceil(rate * 1000) / 100000;
            //固定資産評価額 * 0.7 * 都市計画税率
            return Math.floor(value * 0.7 * rate);
          }
        }
        // 既存建物または付属建物
        else if (id < 11 || id == 21) {
          // 固定資産評価×都市計画税
          return Math.floor((value * rate) / 100);
        }
        // 新築建物
        else if (id < 21) {
          // 新築建物課税標準価格
          let unitPrice = this.getNewBuilding(desc);
          if (unitPrice == null || unitPrice?.length == 0) {
            return 0;
          } else if (unitPrice[0][5] === '-') {
            return Math.floor(
              Math.floor(
                Number(
                  this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[
                    id - 11
                  ]
                )
              ) * 0.8
            );
          }
          return Math.floor((value * rate) / 100); // 延床面積×新築建物課税標準価格×税率
        } else {
          // value = Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[22]); // 評価額
          // return (value * rate) / 100; // 固定資産評価×固定資産税率
          //償却資産に都市計画税は無い
          return 0;
        }
      };
    },
    // 新築工事費 合計
    newConstructionCostsTotal: function() {
      let total = 0;
      const cp = this.investmentAnalytics.initialInvestment.constructionPlan;
      if (cp.selected[0] == 1) {
        for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
          total += Math.floor(Number(cp.newConstructionCost[i]));
        }
      }
      return total;
    },
    // 工事計画 合計
    constructionPlanTotal: function() {
      let total = 0;
      const cp = this.investmentAnalytics.initialInvestment.constructionPlan;
      // 新築工事費
      if (cp.selected[0] == 1) {
        total += this.newConstructionCostsTotal;
      }
      // 修繕工事費
      if (cp.selected[1] == 1) {
        total += Math.floor(Number(cp.repairWorkCost));
      }
      // 設備更新工事費
      if (cp.selected[2] == 1) {
        total += Math.floor(Number(cp.equipmentUpdateCost));
      }
      // 造成工事
      if (cp.selected[3] == 1) {
        total += Math.floor(Number(cp.constructionCost[0].amount));
      }
      // 外構工事
      if (cp.selected[4] == 1) {
        total += Math.floor(Number(cp.constructionCost[1].amount));
      }
      // 建築付帯工事
      if (cp.selected[5] == 1) {
        total += Math.floor(Number(cp.constructionInciDentalWork));
      }
      // 道24条工事
      if (cp.selected[6] == 1) {
        total += Math.floor(Number(cp.constructionCost[6].amount));
      }
      // 解体工事
      if (cp.selected[7] == 1) {
        for (let i = 0; i < cp.demolitionCount; i++) {
          //ループは解体棟数分
          //↓6ではなく7始まりなのは1棟目の工事費が[1 + 6]に代入されている為
          total += Math.floor(Number(cp.constructionCost[7 + i].amount));
        }
      }
      // 土壌改良工事
      if (cp.selected[8] == 1) {
        total += Math.floor(Number(cp.constructionCost[17].amount));
      }
      // 地盤改良工事
      if (cp.selected[9] == 1) {
        total += Math.floor(Number(cp.constructionCost[18].amount));
      }
      // その他
      if (cp.selected[10] == 1) {
        total += Math.floor(Number(cp.otherConstructionFee)); // その他工事費
        total += Math.floor(Number(cp.constructionReserves)); // 工事予備費
      }
      return total;
    },
    // 建築設計費用工事計画 合計
    architecturalDesignCostConstructionPlanTotal: function() {
      const cp = this.investmentAnalytics.initialInvestment.constructionPlan;
      let total = 0;
      // 新築工事費
      if (cp.selected[0] == 1) {
        total += this.newConstructionCostsTotal;
      }
      return total;
    },
    // 不動産取得税(税率)
    realEstateAcquisitionTaxRate: function() {
      return function(kind) {
        let rate = 0;
        switch (kind) {
          case '土地':
            rate = Number(this.getTax('不動産取得税/宅地'));
            break;
          case '居宅':
          case '共同住宅':
            rate = Number(this.getTax('不動産取得税/住宅'));
            break;
          default:
            rate = Number(this.getTax('不動産取得税/非住宅'));
            break;
        }
        return rate;
      };
    },
    // 不動産取得税(税額)
    realEstateAcquisitionTaxAmount: function() {
      return function(type, index) {
        // 「不動産取得税」が無効
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[6] != 1) {
          return 0;
        }
        const ii = this.investmentAnalytics.initialInvestment; // 初期投資
        let isChanged;
        let amount;
        switch (type) {
          // 土地
          case 0:
            isChanged = ii.isChangedRealEstateAcquisitionTax[0];
            amount = Number(ii.realEstateAcquisitionTax[0]);
            break;
          // 既存建物
          case 1:
            isChanged = ii.isChangedRealEstateAcquisitionTax[index + 1];
            amount = Number(ii.realEstateAcquisitionTax[index + 1]);
            break;
          // 新築建物
          case 2:
            isChanged = ii.isChangedRealEstateAcquisitionTax[index + 11];
            amount = Number(ii.realEstateAcquisitionTax[index + 11]);
            break;
          // 付属建物
          case 3:
            isChanged = ii.isChangedRealEstateAcquisitionTax[21];
            amount = Number(ii.realEstateAcquisitionTax[21]);
            break;
        }
        // 変更している場合
        if (isChanged) {
          // 入力値
          return Math.floor(amount);
        }
        // 変更していない場合
        else {
          // 計算値
          return this.realEstateAcquisitionTaxAmountGuideline(type, index);
        }
      };
    },
    // 不動産取得税(税額) ※目安
    realEstateAcquisitionTaxAmountGuideline: function() {
      return function(type, index) {
        // 「不動産取得税」が無効
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[6] != 1) {
          return 0;
        }
        let amount = 0;
        type = Number(type);
        index = Number(index);
        switch (type) {
          // 土地：固定資産評価×税率
          case 0:
            {
              amount =
                (Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[0])
                ) *
                  this.realEstateAcquisitionTaxRate('土地')) /
                100;
            }
            break;
          // 既存建物：固定資産評価×税率
          case 1:
            {
              amount =
                (Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[index + 1])
                ) *
                  this.realEstateAcquisitionTaxRate(
                    this.investmentAnalytics.initialInvestment.propertyDescription[index + 1].kind
                  )) /
                100;
            }
            break;
            // 新築建物：固定資産評価（延床面積×新築建物課税標準価格）×税率
          case 2:
            {
              amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[index + 11])
              ) *
                this.realEstateAcquisitionTaxRate(
                  this.investmentAnalytics.initialInvestment.propertyDescription[index + 11].kind
                )) /
              100;
            }
            break;
          // 付属建物：固定資産評価×税率
          case 3:
            {
              // 付属建物がない時は0を表示
              if (!isNaN(Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[21]))) {
              amount =
                (Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[21])
                ) *
                  this.realEstateAcquisitionTaxRate('非住宅')) /
                100;
              } else {
                amount = 0;
              }
            }
            break;
        }
        //端数切捨て
        return Math.floor(amount);
      };
    },
    // 登録免許税額 ※入力値or計算値
    registrationTaxAmount: function() {
      return function(type, index) {
        // 「登録免許税」が無効
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[7] != 1) {
          return 0;
        }
        let amount = 0;
        type = Number(type);
        index = Number(index);
        const rt = this.investmentAnalytics.initialInvestment.registrationTax;
        let isChanged;
        switch (type) {
          // [0]土地
          case 0:
            isChanged = rt.isChanged[0];
            amount = Number(rt.amount[0]);
            break;
          // [1]既存建物
          case 1:
            isChanged = rt.isChanged[1 + index];
            amount = Number(rt.amount[1 + index]);
            break;
          // [2]新築建物
          case 2:
            isChanged = rt.isChanged[11 + index];
              amount = Number(rt.amount[11 + index]);
            break;
          // [3]抵当権
          case 3:
            if (rt.isChanged[21]) {
              amount = Number(rt.amount[21]);
            } else {
              amount = this.registrationTax(3);
            }
            break;
          // [4]抵当権 ※追加設定
          case 4:
            isChanged = rt.isChanged[22]
            amount = Number(rt.amount[22]);
            break;
          // [5]借地権
          case 5:
            isChanged = rt.isChanged[23]
            amount = Number(rt.amount[23]);
            break;
          // [6]登記記録調査
          case 6:
            isChanged = rt.isChanged[24]
            amount = Number(rt.amount[24]);
            break;
          // [7]付属建物
          case 7:
            isChanged = rt.isChanged[25]
            amount = Number(rt.amount[25]);
            break;
          // [11]所有権移転
          case 11:
            amount = this.registrationTax(11);
            break;
        }
        // 変更している場合
        if (isChanged) {
          // 入力値

          return Math.floor(amount);
        }
        // 変更していない場合
        else {
          // 計算値
          return this.registrationTax(type, index);
        }
      };
    },
    // 登録免許税 ※計算値
    registrationTax: function() {
      return function(type, index) {
        // 「登録免許税」が無効
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[7] != 1) {
          return 0;
        }
        let amount = 0;
        type = Number(type);
        index = Number(index);
        switch (type) {
          // [0]土地 … 固定資産評価×税率
          case 0:
            {
            amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[0])
              ) *
                this.getTax(
                  '登録免許税/土地/' +
                    this.investmentAnalytics.initialInvestment.registrationTax
                      .causeOfRegistration[0]
                )) /
              100;
              //console.log('登録_土地（0）',amount);
            }
            break;
          // [1]既存建物 … 固定資産評価×税率
          case 1:
            {
            amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[index + 1])
              ) *
                this.getTax(
                  '登録免許税/建物/' +
                    this.investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[
                      index + 1
                    ]
                )) /
              100;
            //console.log('登録_既存建物（1）',amount);
            }
            break;
            // [2]新築建物 … 固定資産評価（延床面積×新築建物課税標準価格）×税率
          case 2:
            {
              amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[index + 11])
              ) *
                this.getTax(
                  '登録免許税/建物/所有権の保存'
                )) /
              100;
            // //console.log('登録_新規建物（2）',amount);
            }
            break;

          // [3]抵当権
          case 3:
            {
              if (this.investmentAnalytics.initialInvestment.registrationTax.isChanged[21]) {
                amount = Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.registrationTax.amount[21])
                );
              } else {
                //①抵当権の項目の前までの事業費(=その他②)に1.2をかける。
                //②１の額に0.004をかけ、1万円以下繰り上げをした金額を目安表示してください
                amount =
                  Math.ceil(
                    ((this.totalProjectCostExclude +
                      this.sumTax(this.getUnitPrice('支払手数料', '抵当権設定登記'))) *
                      // this.sumTax(this.paymentFees(2))) *
                      1.2 *
                      0.004) /
                      10000
                  ) * 10000;
              }
              //console.log('抵当権（3）',amount);
            }
            break;
          // [4]抵当権 ※追加設定 … 件数×単価
          case 4:
            {
            amount =
              Math.floor(
                Number(
                  this.investmentAnalytics.initialInvestment.registrationTax
                    .numberOfAdditionalMortgages
                )
              ) * this.getTax('登録免許税/抵当権/追加設定');
              //console.log('抵当権（4）',amount);
            }
            break;
          // [5]借地権 … 固定資産評価(土地)×税率
          case 5:
            {
            amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[0])
              ) *
                this.getTax(
                  '登録免許税/借地権/' +
                    this.investmentAnalytics.initialInvestment.registrationTax
                      .causeOfRegistration[11]
                )) /
              100;
            //console.log('登録_借地権（5）',amount);
            }
            break;
          // [6]登記記録調査 … 件数×単価
          case 6:
            {
            amount =
              Math.floor(
                Number(
                  this.investmentAnalytics.initialInvestment.registrationTax
                    .numberOfRegisteredRecordsSurveyed
                )
              ) * this.getTax('登記手数料/登記事項証明書');
              //console.log('登録_登記記録調査（6）',amount);
            }
            break;
          // [7]付属建物 … 固定資産評価×税率
          case 7:
            {
              // 付属建物がない時は0を表示
              if (!isNaN(Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[21]))) {
            amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[21])
              ) *
                this.getTax(
                  '登録免許税/建物/' +
                    this.investmentAnalytics.initialInvestment.registrationTax
                      .causeOfRegistration[25]
                )) /
              100;
            } else {
              amount = 0;
            }
              //console.log('登録_付属建物（7）',amount);
              }
            break;
          // [11]所有権移転 … 土地および既存建物の合計
          case 11:
            {
            amount =
              (Math.floor(
                Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[0])
              ) *
                this.getTax(
                  '登録免許税/土地/' +
                    this.investmentAnalytics.initialInvestment.registrationTax
                      .causeOfRegistration[0]
                )) /
              100;
            for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
              amount +=
                (Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.propertyTaxValuation[i + 1])
                ) *
                  this.getTax(
                    '登録免許税/建物/' +
                      this.investmentAnalytics.initialInvestment.registrationTax
                        .causeOfRegistration[i + 1]
                  )) /
                100;
            }
            //console.log('所有権移転（11）',amount);
          }
            break;
        }
        return Math.floor(amount);
      };
    },
    // 支払手数料
    paymentFees: function() {
      return function(kind) {
        // 「支払手数料」が無効
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[8] != 1) {
          return 0;
        }
        const isChanged = this.investmentAnalytics.initialInvestment.paymentFees.isChanged[
          Number(kind)
        ];
        const amount = Number(
          this.investmentAnalytics.initialInvestment.paymentFees.amount[Number(kind)]
        );
        // 変更している場合
        if (isChanged) {
          // 入力値
          return Math.floor(amount);
        }
        // 変更していない場合
        else {
          // 計算値
          return this.paymentFeesGuideline(kind);
        }
      };
    },
    // 支払手数料 ※目安
    paymentFeesGuideline: function() {
      return function(kind) {
        let amount = 0;
        let total;
        let iip = this.investmentAnalytics.initialInvestment.paymentFees;
        switch (Number(kind)) {
          // [0]登記記録調査 … 件数×単価
          // ※1,000円以下切上 → 件数×単価 のみの挙動に修正
          case 0:
            if (iip.numberOfCases[kind]) {
              amount =
                Math.floor(
                  Number(iip.numberOfCases[kind])
                ) * this.getUnitPrice('支払手数料', '登記記録調査');
            }
            break;
          // [1]議事録作成 … 件数×単価
          case 1:
            if (iip.numberOfCases[kind]) {
              amount =
                Math.floor(
                  Number(iip.numberOfCases[kind])
                ) * this.getUnitPrice('支払手数料', '議事録作成');
            }
            break;
          // [2]建物保存登記 … 件数×単価
          case 2:
            if (iip.numberOfCases[kind]) {
              amount =
                Math.floor(
                  Number(iip.numberOfCases[kind])
                ) * this.getUnitPrice('支払手数料', '建物保存登記');
            }
            break;
          // [3]-[12]建物表示登記 … 単価
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
            amount = Math.floor(
              this.getUnitPrice(
                '建物表示登記',
                iip.buildingRepresentationRegistration[kind - 3].content
              ) / 100
            );
            break;
          // [13]建物滅失登記 … 件数×単価
          case 13:
            if (iip.numberOfCases[kind]) {
              amount =
                Math.floor(
                  Number(iip.numberOfCases[kind])
                ) * this.getUnitPrice('支払手数料', '建物滅失登記');
            }
            break;
          // [14]地目変更登記 … 件数×単価
          case 14:
            if (iip.numberOfCases[kind]) {
              amount =
                Math.floor(
                  Number(iip.numberOfCases[kind])
                ) * this.getUnitPrice('支払手数料', '地目変更登記');
            }
            break;
          // [15]売買仲介料 … 売買代金（土地＋既存・付属建物）/合計(税抜)×3%＋6万円 ※1,000円以下切上
          case 15:
            amount =
              Math.ceil(
                ((Number(
                  this.investmentAnalytics.initialInvestment.tradingPrice[0] +
                    this.tradingPriceBuilding
                ) *
                  3) /
                  100 +
                  60000) /
                  1000
              ) * 1000;
            break;
             // [16]抵当権設定登記 … 単価
          case 16:
            if (this.registrationTax(3) + this.registrationTax(4) > 0) {
              amount = this.getUnitPrice('支払手数料', '抵当権設定登記');
            }

            break;
          // [17]借地権設定登記 … 単価
          case 17:
            if (this.registrationTax(5) > 0 ||
            this.investmentAnalytics.initialInvestment.registrationTax.amount[23] > 0) {
              amount = this.getUnitPrice('支払手数料', '借地権設定登記');

          }
            break;
          // [18]所有権設定登記 … 単価
          case 18:
             // 所有権移転 合計
             total = this.registrationTaxAmount(0); // 土地
             for (let id = 0; id < this.investmentAnalytics.existingBuildingCount; id++) {
               total += this.registrationTaxAmount(1, id); // 既存建物
             }
             total += this.registrationTaxAmount(7); // 付属建物

            if (total > 0) {
              amount = this.getUnitPrice('支払手数料', '所有権移転登記');

          }
            break;
          // [19-21]金融機関手数料 … 単価
          case 19:
            if (this.registrationTax(3) + this.registrationTax(4) > 0) {
              amount = this.getUnitPrice('支払手数料', '融資事務手数料');
            }

           break;
          case 20:
            if (this.registrationTax(3) + this.registrationTax(4) > 0) {
              amount = this.getUnitPrice('支払手数料', '固定金利手数料');
            }
            break;
          case 21:
            if (this.registrationTax(3) + this.registrationTax(4) > 0) {
              amount = this.getUnitPrice('支払手数料', '抵当権設定手数料');
            }

            break;
          }

        return amount;
      };
    },
    // 事業開始前賃借料
    preBusinessRent: function() {
      // 月額賃借料×負担率
      return Math.floor(
        (Math.floor(
          Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyRent)
        ) *
          Math.floor(
            Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.affordability) *
              100
          )) /
          100 /
          100
      );
    },
    // 事業開始前期間
    preBusinessPeriod: function() {
      // 工事期間＋募集/FR期間＋立退き期間
      const durations = this.investmentAnalytics.initialInvestment.preCareerCommitment.duration;
      const duration =
        Math.floor(durations[0]) + Math.floor(durations[1]) + Math.floor(durations[2]);
      this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[3] = duration;
      this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[4] = duration;
      return duration;
    },
    // 火災保険料(目安)
    fireInsuranceFee: function() {
      return function(id) {
        let total = 0;
        let factor = this.$store.state.master.premiumFactor;
        if (id == 0) {
          // 既存建物分：建物の売買代金合計×保険料長期係数
          for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
            total += Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[i]));
          }

          return Math.ceil((total * parseFloat(factor[0][1])) / 100000) * 100000;
        }
        if (id == 1) {
          // 新築建物分：(新築工事費の合計＋水道工事費＋下水道工事費＋ガス管工事費)×保険料長期係数
          for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
            total += Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[i]
              )
            );
          }
          total += Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[2].amount
            )
          );
          total += Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[3].amount
            )
          );
          total += Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[4].amount
            )
          );
          return Math.ceil((total * parseFloat(factor[0][1])) / 100000) * 100000;
        }
        return 0;
      };
    },
    // 総事業費
    totalProjectCost: function() {
      let total = 0;
      // 総事業費(予備費を除く)
      total = this.totalProjectCostExcludeReserve;
      // 予備費
      total += this.reserveFunds;
      //console.debug({ 総事業費: total });
      return total;
    },
    // 総事業費(予備費を除く)
    totalProjectCostExcludeReserve: function() {
      let total = 0;
      // 土地代(非課税)
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]));
      // 売買代金合計(税込)
      total += this.tradingPriceBuilding;
      total += this.tradingPriceBuildingTax;
      // 工事総額(税込)
      total += this.sumTax(this.constructionPlanTotal);
      // 付帯費用合計 ※予備費を除く
      total += this.incidentalExpensesTotal;
      return total;
    },
    // 総事業費(登録免許税(抵当権)・事業開始前利息・予備費を除く)
    totalProjectCostExclude: function() {
      let total = 0;
      // 土地代(非課税)
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]));
      // 売買代金合計(税込)
      total += this.tradingPriceBuilding;
      total += this.tradingPriceBuildingTax;
      // 工事総額(税込)
      total += this.sumTax(this.constructionPlanTotal);
      // 付帯費用合計 ※登録免許税(抵当権)・事業開始前利息・予備費を除く
      total += this.incidentalExpensesTotalExclude;
      return total;
    },
    // 予備費
    reserveFunds: function() {
      let total = 0;
      // 「その他経費」が有効
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[11] == 1) {
        // 概算入力時はestimatedOtherExpensesに予備費込みで入力されているものとする（ので、ここではゼロ）
        // 詳細入力時
        if (this.investmentAnalytics.initialInvestment.otherExpenses.isDetail == 1) {
          // 予備費を除く総事業費×予備費率
          total =
            (this.totalProjectCostExcludeReserve *
              Math.floor(
                Number(this.investmentAnalytics.initialInvestment.otherExpenses.reserveRate) * 100
              )) /
            100 /
            100;
        }
      }
      //console.debug({ 予備費: total });
      return  Math.floor(total);
    },
    // 火災保険
    fireInsuranceFeesTotal: function() {
      let total = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[10] == 1) {
        total =
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[0])) +
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[1]));
      }
      return total;
    },
    // 施設賠償責任保険
    liabilityInsuranceFees: function() {
      let total = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[10] == 1) {
        total = Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[2]));
      }
      return total;
    },
    // 借入金目安額
    loansGuideline: function() {
      let capitalPlan = this.investmentAnalytics.initialInvestment.capitalPlan;
      let total = 0;
      // 総事業費－(保証金＋敷金＋礼金＋建設協力金＋自己資金)＋調整金
      total += Math.round(this.totalProjectCost);
      total -= Math.floor(Number(capitalPlan.deposit));
      total -= Math.floor(Number(capitalPlan.securityDeposit));
      total -= Math.floor(Number(capitalPlan.keyMoney));
      total -= Math.floor(Number(capitalPlan.constructionCooperationFund));
      total -= Math.floor(Number(capitalPlan.ownResources));
      // 負の場合は0
      return Math.max(0, total);
    },
    // 金銭消費貸借契約用
    loanContract: function() {
      let total = 0;
      // 土地代(非課税)
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]));
      // 売買代金合計(税込)
      total += this.tradingPriceBuilding;
      total += this.tradingPriceBuildingTax;
      // 工事総額(税込)
      total += this.sumTax(this.constructionPlanTotal);
      // 付帯費用（預託金まで）
      total += this.consultingCostTotal; // コンサルタント料
      total += this.designCostTotal; // 設計監理費
      total += this.surveyCostTotal; // 調査測量費
      total += this.licensingCostTotal; // 許認可費
      total += this.contributionsTotal; // 負担金
      total += this.depositsTotal; // 預託金
      // 補正
      total *= this.leverage;


      // 負の場合は0
      return Math.max(0, total);
    },
    // 【単年度収支計画】
    // 賃料/合計
    rentTotal: function() {
      let total = 0;
      //既存建物
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[i].taxation)
        );
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[i].taxExempt)
        );
      }
      //新築建物
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[10 + i].taxation)
        );
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[10 + i].taxExempt)
        );
      }
      return total;
    },
    // 賃料/課税分
    rentTotalTaxation: function() {
      let total = 0;
      //既存建物
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[i].taxation)
        );
      }
      //新築建物
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.rent[10 + i].taxation)
        );
      }
      return total;
    },
    // 共益費/合計
    commonServiceFeesTotal: function() {
      let total = 0;
      //既存建物
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[i].taxation)
        );
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[i].taxExempt)
        );
      }
      //新築建物
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(
          Number(
            this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[10 + i].taxation
          )
        );
        total += Math.floor(
          Number(
            this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[10 + i].taxExempt
          )
        );
      }
      return total;
    },
    // 共益費/課税分
    commonServiceFeesTotalTaxation: function() {
      let total = 0;
      //既存建物
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[i].taxation)
        );
      }
      //新築建物
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += Math.floor(
          Number(
            this.investmentAnalytics.singleYearPlan.incomePlan.commonServiceFees[10 + i].taxation
          )
        );
      }
      return total;
    },
    // 駐車料/合計
    parkingFeesTotal: function() {
      let total = 0;
      for (let i = 0; i < 1; i++) {
        total +=
          Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxation)
          ) *
          Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxationNumber)
          );
        total +=
          Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxExempt)
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxExemptNumber
            )
          );
      }
      return total;
    },
    // 駐車料/課税分
    parkingFeesTotalTaxation: function() {
      let total = 0;
      for (let i = 0; i < 1; i++) {
        total +=
          Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxation)
          ) *
          Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.incomePlan.parkingFees[i].taxationNumber)
          );
      }
      return total;
    },
    // 土地賃貸料/合計
    landRentalFeesTotal: function() {
      let total = 0;
      for (let i = 0; i < 1; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.landRentalFees[i].taxation)
        );
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.landRentalFees[i].taxExempt)
        );
      }
      return total;
    },
    // 土地賃貸料/課税分
    landRentalFeesTotalTaxation: function() {
      let total = 0;
      for (let i = 0; i < 1; i++) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.incomePlan.landRentalFees[i].taxation)
        );
      }
      return total;
    },
    // その他/合計
    othersTotal: function() {
      let total = 0;
      this.investmentAnalytics.singleYearPlan.incomePlan.others.forEach(v => {
        total += Math.floor(Number(v.taxation));
        total += Math.floor(Number(v.taxExempt));
      });
      return total;
    },
    // その他/課税分
    othersTotalTaxation: function() {
      let total = 0;
      this.investmentAnalytics.singleYearPlan.incomePlan.others.forEach(v => {
        total += Math.floor(Number(v.taxation));
      });
      return total;
    },
    // 月収入 ※空室損失考慮 ※空室率変更前
    monthlyIncome: function() {
      let total =
        this.rentTotalTaxation + // 賃料
        this.commonServiceFeesTotalTaxation + // 共益費
        this.parkingFeesTotalTaxation + // 駐車場
        this.landRentalFeesTotalTaxation + // 土地賃貸料
        this.othersTotalTaxation; // その他
      const ratio = this.getRateOfIncrease('空室率-当初');

      return Math.floor(total * (1 - ratio / 100));
    },
    // 月収入 ※空室率変更以降
    monthlyIncomeAfter: function() {
      let total =
        this.rentTotalTaxation + // 賃料
        this.commonServiceFeesTotalTaxation + // 共益費
        this.parkingFeesTotalTaxation + // 駐車場
        this.landRentalFeesTotalTaxation + // 土地賃貸料
        this.othersTotalTaxation; // その他
      const ratio = this.getRateOfIncrease('空室率-変更年数以降');

      return Math.floor(total * (1 - ratio / 100));
    },
    // 軽微な修繕/合計
    minorRepairsTotal: function() {
      let total = 0;
      // 概算入力時
      if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[0] == 0) {
        total = Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[0])
        );
      }
      // 詳細入力時
      else {
        for (let i = 0; i < 4; i++) {
          total +=
            Math.floor(
              (Math.floor(
                Number(
                  this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[i].quantity
                ) * 10
              ) /
                10) *
                Math.floor(
                  Number(
                    this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[i]
                      .unitPrice
                  )
                )
            ) * 12;
        }
      }
      return total;
    },
    // 大規模改装
    LargeScaleRenovationCosts: function() {
      return function(y) {
      let total = 0;
      let repairs = [];
      repairs.push({ name: '大規模改装', values: this.longTermRepairPlanTotals });
      //15・30・45年目の終了年（指定）によって加算開始年を決定
      let yearOfStart = (y == 45 ? 31 : (y == 30 ? 16 : 0))
      //開始年～終了年（指定）までの分を加算
      for(let i = yearOfStart; i < y; i++){
        if(repairs[0].values[i] > 0){
          total += repairs[0].values[i];
        }
      }

      return total;
      };
    },
    // 消耗品/合計
    consumablesTotal: function() {
      let total = 0;
      // 概算入力時
      if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[1] == 0) {
        total = Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[1])
        );
      }
      // 詳細入力時
      else {
        for (let i = 4; i < 6; i++) {
          total +=
            Math.floor(
              Number(
                this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[i].quantity
              )
            ) *
            Math.floor(
              Number(
                this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[i].unitPrice
              )
            ) *
            12;
        }
      }
      return total;
    },
    // ビルメンテナンス/合計
    buildingMaintenanceTotal: function(){
      let total = 0;
      // 概算入力時
      if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[2] == 0) {
        total += Math.floor(
          Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[2])
        );
      }
      // 詳細入力時
      else {
        for (let i = 0; i < this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.length; i++) {
          if(this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].name == '警備'){
            total += Math.floor(
              Number(
                this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
              ) * 12);
          } else {
            total +=
              Math.floor(
                Number(
                this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
                )
              );
                }
        }
      }
      return total;
    },
    // 管理料
    managementFee: function() {
      return function(income) {
        let total = 0;
        switch (Number(this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeType)) {
          // 全体で、[NNN,NNN]円×12
          case 1:
            total =
              Math.floor(
                Number(this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeValue)
              ) * 12;
            break;
          // １区画あたり、[NNN,NNN]円×区画数×12
          case 2:
            total =
              Math.floor(
                Number(this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeValue)
              ) *
              Math.floor(
                Number(this.investmentAnalytics.singleYearPlan.spendingPlan.numberOfRooms)
              ) *
              12;
            break;
          // 収入の、[NNN.NNN]％
          case 3:
            total = Math.floor(
              (income *
                Math.floor(
                  Number(this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeValue) *
                    10
                )) /
                10 /
                100
            );
            break;
          // 上記以外
          default:
            total = null;
            break;
        }
        return total;
      };
    },
    // 【売却】
    // 売却/売却代金内訳/土地/目安
    landSalesPriceGuideline: function() {
      let value = Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]));
      let rate =
        Math.floor(
          Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.assumedLandPriceIncrease) *
            100
        ) / 100;
      // 初期投資/売買代金/土地×（1+想定地価上昇率）
      return Math.floor((value * (100 + rate)) / 100);
    },
    // 売却/売却代金内訳/建物/目安
    buildingSalesPriceGuideline: function() {
      let total = 0;
      // 売却時償却残高の合計
      // 既存建物分
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += this.amortizedBalanceAtSale(0, i);
      }
      // 新築建物分
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += this.amortizedBalanceAtSale(1, i);
      }
      return total;
    },
    // 売却/売却代金内訳/合計
    salePriceTotal: function() {
      return (
        Math.floor(Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.land)) +
        this.sumTax(
          Math.floor(Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.building)),
          Math.floor(
            Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.depreciatedAssets)
          )
        )
      );
    },
    // 売却/売却時経費/解体工事/合計
    demolitionTotal: function() {
      let total = 0;
      // 詳細入力時
      if (this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition.isDetail > 0) {
        // 既存建物
        let ids = [...Array(this.investmentAnalytics.existingBuildingCount)].map((_, i) => i);
        for (let id of ids) {
          total += Math.floor(
            Number(
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition.details[id]
                .amount
            )
          );
        }
        // 新築建物
        ids = [...Array(this.investmentAnalytics.newBuildingCount)].map((_, i) => i);
        for (let id of ids) {
          total += Math.floor(
            Number(
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition.details[10 + id]
                .amount
            )
          );
        }
      }
      // 概算入力時
      else {
        total = Math.floor(
          Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition.total)
        );
      }
      return total;
    },
    // 売却/売却時経費/修繕・Valueup工事費/合計
    repairTotal: function() {
      let total = 0;
      // 概算入力時
      if (
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork.isDetail == 0
      ) {
        total += Math.floor(
          Number(
            this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork.total
          )
        );
      }
      // 詳細入力時
      else {
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork.details.forEach(
          v => {
            total += Math.floor(Number(v.amount));
          }
        );
      }
      return this.sumTax(total);
    },
    // 売却/売却時経費/調査費/合計
    surveyCostAtSaleTotal: function() {
      let total = 0;
      total += this.getUnitPrice(
        '土壌汚染調査',
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.soilContaminationSurvey
      );
      total += this.getUnitPrice(
        'アスベスト調査',
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.asbestosUseStudy
      );
      total += this.getUnitPrice(
        '設備機能調査',
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.equipmentFunctionSurvey
      );
      total += this.getUnitPrice(
        'インスペクション',
        this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.inspection
      );
      total += Math.floor(
        Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.otherSurveys)
      );
      return total;
    },
    // 売却/譲渡税計算/取得費/合計
    acquisitionFeesTotal: function() {
      let total = 0;
      // [土地]初期投資/売買代金/土地
      total += Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]));
      // [既存建物]売却時償却残高;
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        total += this.amortizedBalanceAtSale(0, i);
      }
      // [新築建物]売却時償却残高;
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += this.amortizedBalanceAtSale(1, i);
      }
      // [付属建物]売却時償却残高;
      total += this.amortizedBalanceAtSale(2);
      return total;
    },
    // 売却/譲渡税計算/譲渡費/売買仲介料
    brokerageFees: function() {
      if (this.salePriceTotal > 0) {
        // （売却/売却代金内訳/合計の３％＋６万円）×（１＋消費税率）
        let fees = Math.floor((this.salePriceTotal * 3) / 100);
        fees += 60000;
        return this.sumTax(fees);
      }
      return 0;
    },
    // 売却/譲渡税計算/譲渡費/立退料・補償料
    evictionAndCompensationFees: function() {
      let total = 0;
      total +=
        Math.floor(Number(this.investmentAnalytics.salePlan.evictionFees[0])) *
        this.getUnitPrice('立退料', '個人');
      total +=
        Math.floor(Number(this.investmentAnalytics.salePlan.evictionFees[1])) *
        this.getUnitPrice('立退料', '法人・個人事業主');
      total += this.investmentAnalytics.salePlan.businessCompensation.reduce((acc, value) => {
        return (
          acc + Math.floor(Number(value.quantity)) * this.getUnitPrice('営業補償', value.content)
        );
      }, 0);
      return total;
    },
    // 売却/譲渡税計算/譲渡費/合計
    transferCostTotal: function() {
      let total = 0;
      total += this.brokerageFees; // 売買仲介料
      total += this.getStampDuty(this.salePriceTotal, 0); // 売買契約印紙代
      total += Math.floor(
        Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.surveyingCosts.amount)
      ); // 測量費
      total += this.evictionAndCompensationFees; // 立退料・補償料
      total += this.demolitionTotal; // 解体費
      total += this.repairTotal; // 補修費
      return total;
    },
    // 売却/譲渡税計算/譲渡所得税/課税所得
    taxableIncome: function() {
      let total = 0;
      // 売却代金（税込）－取得費－譲渡費
      total += this.salePriceTotal;
      total -= this.acquisitionFeesTotal;
      total -= this.transferCostTotal;
      return total;
    },
    // 売却/譲渡税計算/譲渡所得税[個人]/課税区分
    taxableCategory: function() {
      let year = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      // 未入力
      if (year === null || year === undefined || year === '' || year < 1) {
        return null;
      }
      // 5年以下
      else if (year <= 5) {
        return '短期譲渡';
      }
      // 5年超
      else {
        return '長期譲渡';
      }
    },
    // 売却/譲渡税計算/譲渡所得税/譲渡所得税・住民税
    transferIncomeTax: function() {
      let income = this.taxableIncome;
      let year = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      return Math.max(0, this.getTransferIncomeTax(income, year));
    },
    // 売却/譲渡税計算/法人税/法人税
    corporationTax: function() {
      let income = this.taxableIncome;
      let kind = this.investmentAnalytics.salePlan.capital;
      return Math.max(0, this.getCorporationTax(income, kind));
    },
    // 売却/譲渡税計算/法人税/住民税
    municipalTax: function() {
      let income = this.taxableIncome;
      let rate = this.getTax('法人住民税');
      return Math.max(0, Math.floor((income * rate) / 100));
    },
    // 売却/譲渡税計算/法人税/事業税
    businessTax: function() {
      let income = this.taxableIncome;
      let rate = this.getTax('法人事業税');
      return Math.max(0, Math.floor((income * rate) / 100));
    },
    // 投資計画/付帯費用/合計  ※予備費を除く
    incidentalExpensesTotal: function() {
      let items = [
        {
          value: this.consultingCostTotal,
          name: 'コンサルタント料',
        },
        {
          value: this.designCostTotal,
          name: '設計監理費',
        },
        {
          value: this.surveyCostTotal,
          name: '調査測量費',
        },
        {
          value: this.licensingCostTotal,
          name: '許認可費',
        },
        {
          value: this.contributionsTotal,
          name: '負担金',
        },
        {
          value: this.depositsTotal,
          name: '預託金',
        },
        {
          value: this.publicDuesTotal,
          name: '公租公課',
        },
        {
          value: this.paymentFeesTotalExclude,
          name: '支払手数料(抵当権設定登記を除く)',
        },
        {
          value:
          // 登録免許税がオンで支払手数料がオフ
          this.investmentAnalytics.initialInvestment.incidentalExpenses[7] == 1 &&
          this.investmentAnalytics.initialInvestment.incidentalExpenses[8] == 0 ? (
            // 抵当権があるときは計上
            this.registrationTax(3) + this.registrationTax(4) > 0 ?
              this.sumTax(this.getUnitPrice('支払手数料', '抵当権設定登記')) :
              0
          ) :
          // 支払手数料の概算入力がオン
            this.investmentAnalytics.initialInvestment.paymentFees.isDetail == 0 ||
          // 抵当権設定登記の金額が自動または手入力で0円
          !(this.paymentFees(16) > 0)
              ? 0
              : this.sumTax(this.paymentFees(16)),
          name: '支払手数料(抵当権)',
        },
        {
          value: this.rentalFeeTotal,
          name: '賃借料',
        },
        {
          value: this.interestExpenseTotal2,
          name: '支払利息',
        },
        {
          value: this.fireInsuranceFeesTotal + this.liabilityInsuranceFees,
          name: '損害保険料',
        },
        {
          value: this.otherTotal,
          name: 'その他 ※予備費を除く',
        },
      ];

      let total = items.reduce((a, v) => {
        //console.debug(v);
        if (isNaN(v.value) == true) v.value = 0;
        return a + Number(v.value);
      }, 0);
      //console.debug({ '付帯費用(予備費除く)': total });
      return total;
    },
    // 投資計画/付帯費用/合計   ※登録免許税(抵当権)・事業開始前利息・予備費を除く
    incidentalExpensesTotalExclude: function() {
      let total = 0;
      total += this.consultingCostTotal; // コンサルタント料
      total += this.designCostTotal; // 設計監理費
      total += this.surveyCostTotal; // 調査測量費
      total += this.licensingCostTotal; // 許認可費
      total += this.contributionsTotal; // 負担金
      total += this.depositsTotal; // 預託金
      total += this.publicDuesTotalExclude; // 公租公課  ※登録免許税(抵当権)を除く
      // 以下は不要
      // total += this.paymentFeesTotalExclude; // 支払手数料  ※抵当権設定登記を除く
      // total += this.rentalFeeTotal; // 賃借料
      // total += this.interestExpenseTotal; // 支払利息 ※事業開始前利息を除く
      // total += this.otherTotal; // その他 ※予備費を除く
      return total;
    },
    // 投資計画/付帯費用/コンサルタント料/小計
    consultingCostTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[0] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailConsultingCost == 0) {
        total = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.estimatedConsultingCost)
        );
      }
      // 詳細入力
      else {
        total = this.investmentAnalytics.initialInvestment.consultingCost.reduce((acc, value) => {
          return acc + Math.floor(Number(value.amount));
        }, 0);
        //その他コンサル
        total += Math.floor(Number(this.investmentAnalytics.initialInvestment.otherConsultingCost));
      }
      return this.sumTax(total); // 税込
    },
    // 投資計画/付帯費用/設計監理費/小計
    designCostTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[1] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailDesignCost == 0) {
        total = Math.floor(Number(this.investmentAnalytics.initialInvestment.estimatedDesignCost));
      }
      // 詳細入力
      else {
        total = this.investmentAnalytics.initialInvestment.designCost.reduce((acc, value) => {
          return acc + Math.floor(Number(value.amount));
        }, 0);
      }
      return this.sumTax(total); // 税込
    },
    // 投資計画/付帯費用/調査測量費/小計
    surveyCostTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[2] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailSurveyCost == 0) {
        total = Math.floor(Number(this.investmentAnalytics.initialInvestment.estimatedSurveyCost));
      }
      // 詳細入力
      else {
        total = this.investmentAnalytics.initialInvestment.surveyCost.reduce((acc, value) => {
          return acc + Math.floor(Number(value.amount));
        }, 0);
      }
      return this.sumTax(total); // 税込
    },
    // 投資計画/付帯費用/許認可費/小計
    licensingCostTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[3] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailLicensingCost == 0) {
        total = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.estimatedLicensingCost)
        );
      }
      // 詳細入力
      else {
        total = this.investmentAnalytics.initialInvestment.licensingCost.reduce((acc, value) => {
          return acc + Math.floor(Number(value.amount));
        }, 0);
      }
      return this.sumTax(total); // 税込
    },
    // 投資計画/付帯費用/負担金/小計
    contributionsTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[4] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailContributionsAndDeposits == 0) {
        total = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.estimatedContributions)
        );
      }
      // 詳細入力
      else {
        // [0]上水道加入金
        // [1]下水受益者負担金
        // [2]改良区決済金
        // [3]団地負担金
        // [4]開発協力金
        total = this.investmentAnalytics.initialInvestment.contributionsAndDeposits.reduce(
          (acc, value, index) => {
            return acc + (index < 5 ? Math.floor(Number(value.amount)) : 0);
          },
          0
        );
      }
      return total;
    },
    // 投資計画/付帯費用/預託金/小計
    depositsTotal: function() {
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[4] == 0) {
        return 0;
      }
      let total = 0;
      // 概算入力
      if (this.investmentAnalytics.initialInvestment.isDetailContributionsAndDeposits == 0) {
        total = Math.floor(Number(this.investmentAnalytics.initialInvestment.estimatedDeposits));
      }
      // 詳細入力
      else {
        // [5]敷金
        // [6]建設協力金
        // [7]保証金
        total = this.investmentAnalytics.initialInvestment.contributionsAndDeposits.reduce(
          (acc, value, index) => {
            return acc + (index >= 5 ? Math.floor(Number(value.amount)) : 0);
          },
          0
        );
      }
      return total;
    },
    // 公租公課  ※登録免許税(抵当権)を除く
    publicDuesTotalExclude: function() {
      let total = 0;
      // 初期投資
      let iv = this.investmentAnalytics.initialInvestment;
      // 既存建物インデックス
      let existingBuildings = [...Array(this.investmentAnalytics.existingBuildingCount)].map(
        (_, i) => i
      );
      // 新築建物インデックス
      let newBuildings = [...Array(this.investmentAnalytics.newBuildingCount)].map((_, i) => i);
      // 印紙税
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[5] == 1) {
        // 概算入力
        if (this.investmentAnalytics.initialInvestment.isDetailStampDuty == 0) {
          let estimatedStampDuty = Math.floor(
            Number(this.investmentAnalytics.initialInvestment.estimatedStampDuty)
          );
          total += isNaN(estimatedStampDuty) == true ? 0 : estimatedStampDuty;
        }
        // 詳細入力
        else {
          // 印紙税の詳細は自動計算の結果も変数に代入されている
          for (let id = 0; id < iv.stampDuty.length; id++) {
            total += Math.floor(iv.stampDuty[id]);
          }
        }
      }
      // 不動産取得税
      total +=
        isNaN(this.realEstateAcquisitionTaxAmount(0)) == true
          ? 0
          : this.realEstateAcquisitionTaxAmount(0); // 土地
      for (let id of existingBuildings) {
        total +=
          isNaN(this.realEstateAcquisitionTaxAmount(1, id)) == true
            ? 0
            : this.realEstateAcquisitionTaxAmount(1, id); // 既存建物
      }
      for (let id of newBuildings) {
        total +=
          isNaN(this.realEstateAcquisitionTaxAmount(2, id)) == true
            ? 0
            : this.realEstateAcquisitionTaxAmount(2, id); // 新築建物
      }
      total +=
        isNaN(this.realEstateAcquisitionTaxAmount(3)) == true
          ? 0
          : this.realEstateAcquisitionTaxAmount(3); // 付属建物
      // 登録免許税
      //let rta = 0;
      for (let id of [0, 4, 5, 6, 7]) {
        // [0]土地
        // [4]抵当権 ※追加設定
        // [5]借地権
        // [6]登記記録調査
        // [7]付属建物
        total += isNaN(this.registrationTaxAmount(id)) ? 0 : this.registrationTaxAmount(id);
      }
      for (let id of existingBuildings) {
        total += isNaN(
          this.registrationTaxAmount(1, id)) ? 0 : this.registrationTaxAmount(1, id); // [1]既存建物
      }
      for (let id of newBuildings) {
        total += isNaN(
          this.registrationTaxAmount(2, id)) ? 0 : this.registrationTaxAmount(2, id); // [2]新築建物
      }

      // 固定資産税/都市計画税(土地)
      let land = Math.floor(Number(iv.propertyTax[0])) + Math.floor(Number(iv.cityPlanningTax[0]));
      total += isNaN(land) == true ? 0 : land;
      // 固定資産税/都市計画税(既存建物)
      for (let id of existingBuildings) {
        let existing =
          Math.floor(Number(iv.propertyTax[id + 1])) +
          Math.floor(Number(iv.cityPlanningTax[id + 1]));
        total += isNaN(existing) == true ? 0 : existing;
      }
      // 固定資産税/都市計画税(新築建物)
      for (let id of newBuildings) {
        let newBuilding =
          Math.floor(Number(iv.propertyTax[id + 11])) +
          Math.floor(Number(iv.cityPlanningTax[id + 11]));
        total += isNaN(newBuilding) == true ? 0 : newBuilding;
      }
      // 固定資産税(付属建物)
      total += Math.floor(Number(iv.propertyTax[21]));
      // 都市計画税（付属建物）
      total += Math.floor(Number(iv.cityPlanningTax[21]));
      // 固定資産税(償却資産)
      total += Math.floor(Number(iv.propertyTax[22]));
      return total;
    },
    // 投資計画/付帯費用/公租公課/小計
    publicDuesTotal: function() {
      let total = this.registrationTaxAmount(3); // 登録免許税[3]抵当権
      if (total > 0) {
        total += this.publicDuesTotalExclude; // 公租公課（登録免許税[抵当権]を除く）
       } else {
        total = this.publicDuesTotalExclude;
        // 抵当権がなかったら、公租公課（登録免許税[抵当権]を除く）の金額を反映する
      }
      return total;
    },
    // 投資計画/付帯費用/支払手数料/小計 ※抵当権設定登記を除く
    paymentFeesTotalExclude: function() {
      let total = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[8] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.paymentFees.isDetail == 0) {
          total = Math.floor(
            Number(this.investmentAnalytics.initialInvestment.paymentFees.estimatedPaymentFee)
          );
        }
        // 詳細入力時
        else {
          // [0]登記記録調査
          // [1]議事録作成
          // [2]建物保存登記
          // [13]建物滅失登記
          // [14]地目変更登記
          // [15]売買仲介料
          for (let id of [0, 1, 2, 13, 14, 15]) {
            //console.debug({ id: id, amount: this.paymentFees(id) });
            total += this.paymentFees(id);
          }
          // [3]～[12]建物表示登記
          for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
            total += this.paymentFees(3 + i);
          }
          // 賃貸借仲介料
          total += Math.floor(
            Number(this.investmentAnalytics.initialInvestment.paymentFees.leaseBrokerageFee)
          );
          // 「登録免許税->所有権移転（土地・既存建物・付属建物）」がある場合
          total += this.paymentFees(18);

          // 金融機関関連の手数料
          //融資事務手数料
          total += this.paymentFees(19);
          //固定金利手数料
          total += this.paymentFees(20);
          //抵当権設定手数料
          total += this.paymentFees(21);

          // 「登録免許税->借地権設定」がある場合
          // 借地権設定登記
          total += this.paymentFees(17);

        }
      }
      return this.sumTax(total); // 税込
    },
    // 投資計画/付帯費用/賃借料/小計
    rentalFeeTotal: function() {
      let total = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 0) {
          total += this.sumTax(
            Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.approximateValue
              )
            )
          );
        }
        // 詳細入力時
        else {
          // 事業開始前賃借料
          total +=
            ((Math.floor(
              this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyRent
            ) *
              Math.floor(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.affordability
              )) /
              100) *
            this.preBusinessPeriod;
          // 事業開始前占用料
          total +=
            Math.floor(
              this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyOccupancyFee
            ) *
            Math.floor(
              Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[3])
            );
        }
      }
      return total;
    },
    // 初期投資/事業開始前負担/支払利息
    // 投資計画/付帯費用/支払利息/小計
    /* interestExpenseTotal: function() {
      // 変更時
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&  //事業開始前負担
          this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 1 && //詳細入力
          this.investmentAnalytics.initialInvestment.preCareerCommitment.isChanged) {  //手入力
            return Math.round(Number(
              this.investmentAnalytics.initialInvestment.preCareerCommitment
                .interestBeforeStartingBusiness
            ));
      }
      let total = 0;
      // その他1 ※登録免許税(抵当権)・事業開始前利息・予備費を除く総事業費－自己資金＋調整金
      let etc1 =
        Number(this.totalProjectCostExclude) +
        this.sumTax(this.getUnitPrice('支払手数料', '抵当権設定登記')) -
        Math.round(Number(this.investmentAnalytics.initialInvestment.capitalPlan.deposit)) -
        Math.round(Number(this.investmentAnalytics.initialInvestment.capitalPlan.securityDeposit)) -
        Math.round(Number(this.investmentAnalytics.initialInvestment.capitalPlan.keyMoney)) -
        Math.round(Number(this.investmentAnalytics.initialInvestment.capitalPlan.constructionCooperationFund)) -
        Math.round(Number(this.investmentAnalytics.initialInvestment.capitalPlan.ownResources));
      // その他2 ※登録免許税(抵当権)・事業開始前利息・予備費を除く総事業費
      let etc2 = Number(this.totalProjectCostExclude) + this.sumTax(this.getUnitPrice('支払手数料', '抵当権設定登記'));
      // 予備費率
      let rate1 = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[11] == 1 &&
          this.investmentAnalytics.initialInvestment.otherExpenses.isDetail == 1) {
          rate1 = Math.round(Number(this.investmentAnalytics.initialInvestment.otherExpenses.reserveRate) * 100) / 100 / 100;
      }
      // 事業開始前利率
      let rate2 = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&
        this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 1) {
          rate2 = (Math.round(Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.borrowingRate) * 100) / 100 *
            Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[4])) /
            12 /
            100;
      }
      // 登録免許税[抵当権]
      let mortgage = this.registrationTaxAmount(3);
      //  事業開始前利息 =
      //    (登録免許税 * (事業開始前利率 + 予備費率 * 事業開始前利率) +
      //      その他1 * 事業開始前利率 +
      //      その他2 * 予備費率 * 事業開始前利率) /
      //    (1 - (事業開始前利率 + 予備費率 * 事業開始前利率));
      total =
        (mortgage * (rate2 + rate1 * rate2) + etc1 * rate2 + etc2 * rate1 * rate2) /
        (1 - (rate2 + rate1 * rate2));
      //console.debug({ 支払利息: total });
      return Math.round(total);
    }, */
    //借入額を入力できるパターン
    interestExpenseTotal2: function() {
      let amount = 0;
      //借入金額
      let borrowedAmount = this.investmentAnalytics.initialInvestment.capitalPlan.borrowedAmount;
      // 事業開始前利率
      let rate2 = 0;
      if (
        this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&
        this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail
      ) {
        // 事業開始前利率＝(((利率*100)/100)*事業開始前期間)/12/100
        rate2 =
          ((Math.floor(
            Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.borrowingRate) *
              100
          ) /
            100) *
            Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[4])) /
          12 /
          100;
        //借入金額 * 事業開始前利率
        amount = Math.floor(borrowedAmount * rate2);
      }
      return amount;
    },
    // 投資計画/付帯費用/その他/小計 ※予備費を除く
    otherTotal: function() {
      let other = this.investmentAnalytics.initialInvestment.otherExpenses;
      let total = 0;
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[11] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.otherExpenses.isDetail == 0) {
          total = Math.floor(
            Number(this.investmentAnalytics.initialInvestment.otherExpenses.estimatedOtherExpenses)
          );
        }
        // 詳細入力時
        else {
          // 祭祀費
          total += Math.floor(Number(other.ritualCost));
          // 近隣対策費
          total += Math.floor(Number(other.neighborhoodWatchCosts.amount));
          // 立退料/個人
          total +=
            Math.floor(Number(other.evictionFeesQuantity[0])) * this.getUnitPrice('立退料', '個人');
          // 立退料/法人・個人事業主
          total +=
            Math.floor(Number(other.evictionFeesQuantity[1])) *
            this.getUnitPrice('立退料', '法人・個人事業主');
          // 営業補償
          total += other.businessCompensation.reduce((acc, value) => {
            return (
              acc +
              Math.floor(Number(value.quantity)) *
                (value == null ? 0 : this.getUnitPrice('営業補償', value.content))
            );
          }, 0);
          total += Math.floor(Number(other.advertisingExpenses)); // 広告費
          // 特殊経費
          total += other.specialExpenses.reduce((acc, value) => {
            return acc + Math.floor(Number(value.amount));
          }, 0);
          total += Math.floor(Number(other.miscellaneousExpenses)); // 諸雑費
        }
      }
      return total;
    },
    // [減価償却]取得価格
    acquisitionPrice: function() {
      return function(type, index) {
        let price = 0;
        switch (Number(type)) {
          case 0:
            // [既存]初期投資/売買代金/既存建物
            price = Math.floor(
              Number(this.investmentAnalytics.initialInvestment.tradingPrice[1 + index])
            );
            break;
          case 1:
            // [新規]初期投資/工事計画/建築/新築工事費
            price = Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[
                  index
                ]
              )
            );
            break;
          case 2:
            // [既存]初期投資/売買代金/付属建物
            price = Math.floor(Number(this.investmentAnalytics.initialInvestment.tradingPrice[21]));
            break;
        }
        return price;
      };
    },
    // [減価償却]法定耐用年数
    legalServiceLife: function() {
      return function(type, index) {
        let building = null;
        switch (type) {
          // 既存建物
          case 0:
            building = this.investmentAnalytics.initialInvestment.propertyDescription[index + 1];
            break;
          // 新築建物
          case 1:
            building = this.investmentAnalytics.initialInvestment.propertyDescription[index + 11];
            break;
          // 付属建物
          case 2:
            building = this.investmentAnalytics.initialInvestment.propertyDescription[21];
            break;
          default:
            return 0;
        }
        // 種類・構造から「法定耐用年数表」を検索
        let years = 0;
        if (type == 2) {
          // 付属建物の細目は'工場/倉庫'として扱う
          years = this.getLegalServiceLife(building.structure, '工場/倉庫');
        } else {
          years = this.getLegalServiceLife(building.structure, building.kind);
        }
        return years;
      };
    },
    // [減価償却]適用耐用年数
    applicableServiceLife: function() {
      return function(type, index) {
        // 法定耐用年数
        let years = this.legalServiceLife(type, index);
        switch (type) {
          // 既存建物
          // 付属建物
          case 0:
          case 2:
            {
              // 購入時経過年数
              let elapsed = this.ageOfBuilding(Number(type) == 0 ? index + 1 : 21);
              // 耐用年数＞経過年数
              if (years > elapsed) {
                // 法定耐用年数－購入時経過年数＋購入時経過年数×0.2 ※端数切捨
                years = Math.floor(years - elapsed + elapsed * 0.2);
              }
              // 耐用年数≦経過年数
              else {
                // 法定耐用年数 × 0.2 ※端数切捨
                years = Math.floor(years * 0.2);
              }
            }
            return years;
          // 新築建物
          case 1:
            // 法定耐用年数
            return years;
          default:
            return 0;
        }
      };
    },
    // [減価償却]売却時償却残高
    // 引数：建物種別(0:既存、1:新規、2:付属建物)、建物index(0～9)
    amortizedBalanceAtSale: function() {
      return function(type, index = 0) {
        // 構造・種別・築年数から「適用耐用年数」を取得
        let year = this.applicableServiceLife(type, index);
        // 適用耐用年数から「定額法-償却率」を取得
        let rate = this.getDepreciationRate(year, 1);
        // 取得価格
        let amount = this.acquisitionPrice(type, index);
        // 償却額＝取得価格×償却率
        // 厳密には耐用最終年は-1円？ https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/2106.htm
        let depreciation = amount * rate;
        // 売却時償却残高＝取得価格－償却額×売却年 ※端数切上
        let balance = Math.ceil(
          amount - depreciation * Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale))
        );
        // 負の場合は０
        return Math.max(0, balance);
      };
    },
    // 契約金額
    // 引数：種別
    contractAmount: function() {
      return function(id) {
        let amount = 0;
        switch (id) {
          // 売買契約
          case 0:
            // 売買代金/合計(税抜)
            amount += this.tradingPriceTotal;
            break;
          // 工事契約
          case 1:
            // 初期費用/工事計画/合計
            amount += this.constructionPlanTotal;
            break;
          // コンサル契約
          case 2:
            // 付帯費用/コンサルタント料
            if (this.investmentAnalytics.initialInvestment.incidentalExpenses[0] == 1) {
              // 概算入力時
              if (this.investmentAnalytics.initialInvestment.isDetailConsultingCost == 0) {
                amount += Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.estimatedConsultingCost)
                );
              }
              // 詳細入力時
              else {
                amount += this.investmentAnalytics.initialInvestment.consultingCost.reduce(
                  (a, v) => {
                    return a + Math.floor(Number(v.amount));
                  },
                  0
                );
                //その他コンサル
                amount += Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.otherConsultingCost)
                );
              }
            }
            break;
          // 設計監理契約
          case 3:
            // 付帯費用/設計監理費
            if (this.investmentAnalytics.initialInvestment.incidentalExpenses[1] == 1) {
              // 概算入力時
              if (this.investmentAnalytics.initialInvestment.isDetailDesignCost == 0) {
                amount += Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.estimatedDesignCost)
                );
              }
              // 詳細入力時
              else {
                amount += this.investmentAnalytics.initialInvestment.designCost.reduce((a, v) => {
                  return a + Math.floor(Number(v.amount));
                }, 0);
              }
            }
            break;
          // 金銭消費貸借契約
          case 4:
            // 資金計画/借入金
            // 印紙税対応
            amount = this.loanContract;
            break;
          // 賃貸借契約
          case 5:
            {
              const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 初期投資/資金計画
              amount += Math.floor(Number(cp.deposit)); // 保証金
              amount += Math.floor(Number(cp.constructionCooperationFund)); // 建設協力金
              amount += Math.floor(Number(cp.securityDeposit)); // 敷金
            }
            break;
          // 上記以外
          default:
            amount = 0;
        }
        // 未変更時
        if (!this.investmentAnalytics.initialInvestment.isChangedStampDuty[id]){
          //最新の印紙税額と契約金変更前の印紙税額と違う場合
          if(this.getStampDuty(amount, id) != this.investmentAnalytics.initialInvestment.stampDuty[id]){
            this.$set(
              this.investmentAnalytics.initialInvestment.stampDuty,
              id,
              this.getStampDuty(amount, id)
            );
          }
        }
        return amount;
      };
    },
    calcIncidentalExpensesComputed: function() {
      let result = {
        name: '付帯費用',
        details: [], // 詳細
        total: 0, // 合計
      };
      // コンサルタント料
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[0] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.isDetailConsultingCost == 0) {
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedConsultingCost)) {
            result.details.push({
              name: 'コンサルタント料',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.estimatedConsultingCost)
                )
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          const names = [
            '開発コンサル',
            '税務コンサル',
            '法務コンサル',
            '不動産コンサル',
            // '権利者調整',
            // 'その他コンサル',
          ];
          let details = [];
          for (let id = 0; id < names.length; id++) {
            if (
              !this.isEmpty(this.investmentAnalytics.initialInvestment.consultingCost[id].amount)
            ) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.consultingCost[id].amount)
                ),
                summary: null,
              });
            }
          }
          //その他コンサル
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.otherConsultingCost)) {
            details.push({
              name: 'その他コンサル',
              quantity: 1,
              unit: '式',
              amount: Math.floor(
                Number(this.investmentAnalytics.initialInvestment.otherConsultingCost)
              ),
              summary: null,
            });
          }
          if (details.length > 0) {
            // 消費税
            let total = details.reduce((a, v) => {
              return a + v.amount;
            }, 0); // ここまでの合計
            details.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            result.details.push({
              name: 'コンサルタント料',
              details: details,
              total: this.sumTax(total),
            });
          }
        }
      }
      // 設計監理費
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[1] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.isDetailDesignCost == 0) {
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedDesignCost)) {
            result.details.push({
              name: '設計監理費',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(Number(this.investmentAnalytics.initialInvestment.estimatedDesignCost))
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          const names = ['建築設計', '造成設計', '工事監理'];
          let details = [];
          for (let id = 0; id < names.length; id++) {
            if (!this.isEmpty(this.investmentAnalytics.initialInvestment.designCost[id].amount)) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.designCost[id].amount)
                ),
                summary: null,
              });
            }
          }
          if (details.length > 0) {
            // 消費税
            let total = details.reduce((a, v) => {
              return a + v.amount;
            }, 0); // ここまでの合計
            details.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            result.details.push({
              name: '設計監理費',
              details: details,
              total: this.sumTax(total),
            });
          }
        }
      }
      // 調査測量費
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[2] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.isDetailSurveyCost == 0) {
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedSurveyCost)) {
            result.details.push({
              name: '調査測量費',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(Number(this.investmentAnalytics.initialInvestment.estimatedSurveyCost))
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          const names = [
            '地耐力調査',
            '土壌汚染調査',
            '公簿調査',
            '現況測量',
            '確定測量',
            '建築基準法適合調査',
          ];
          let details = [];
          for (let id = 0; id < names.length; id++) {
            if (!this.isEmpty(this.investmentAnalytics.initialInvestment.surveyCost[id].amount)) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.surveyCost[id].amount)
                ),
                summary: null,
              });
            }
          }
          if (details.length > 0) {
            // 消費税
            let total = details.reduce((a, v) => {
              return a + v.amount;
            }, 0); // ここまでの合計
            details.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            result.details.push({
              name: '調査測量費',
              details: details,
              total: this.sumTax(total),
            });
          }
        }
      }
      // 許認可費
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[3] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.isDetailLicensingCost == 0) {
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedLicensingCost)) {
            result.details.push({
              name: '許認可費',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.estimatedLicensingCost)
                )
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          const names = [
            '建築確認申請',
            '建築完了検査',
            '構造計算適合判定',
            '道路切り下げ工事許可',
            '開発許可',
            '都市計画法53条許可',
            '農地転用許可',
            '宅造規制法許可',
            '公拡法届出',
            '土対法届出',
            '大店立地法',
            '占用許可',
            '消防法',
            'その他申請費用',
          ];
          let details = [];
          for (let id = 0; id < names.length; id++) {
            if (
              !this.isEmpty(this.investmentAnalytics.initialInvestment.licensingCost[id].amount)
            ) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.licensingCost[id].amount)
                ),
                summary: null,
              });
            }
          }
          if (details.length > 0) {
            // 消費税
            let total = details.reduce((a, v) => {
              return a + v.amount;
            }, 0); // ここまでの合計
            details.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            result.details.push({
              name: '許認可費',
              details: details,
              total: this.sumTax(total),
            });
          }
        }
      }
      // 各種負担金・預託金
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[4] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.isDetailContributionsAndDeposits == 0) {
          // 負担金
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedContributions)) {
            result.details.push({
              name: '負担金',
              quantity: 1,
              unit: '式',
              amount: Math.floor(
                Number(this.investmentAnalytics.initialInvestment.estimatedContributions)
              ),
              summary: null,
            });
          }
          // 預託金
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedDeposits)) {
            result.details.push({
              name: '預託金',
              quantity: 1,
              unit: '式',
              amount: Math.floor(
                Number(this.investmentAnalytics.initialInvestment.estimatedDeposits)
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          const names = [
            '上水道加入金',
            '下水受益者負担金',
            '改良区決済金',
            '団地負担金',
            '開発協力金',
            '敷金',
            '建設協力金',
            '保証金',
          ];
          // 負担金
          let details = [];
          for (let id of [0, 1, 3, 4, 2]) {
            if (
              !this.isEmpty(
                this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].amount
              )
            ) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(
                    this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].amount
                  )
                ),
                summary: null,
              });
            }
          }
          if (details.length > 0) {
            result.details.push({
              name: '負担金',
              details: details,
              total: details.reduce((a, v) => {
                return a + v.amount;
              }, 0),
            });
          }
          // 預託金
          details = [];
          for (let id of [5, 6, 7]) {
            if (
              !this.isEmpty(
                this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].amount
              )
            ) {
              details.push({
                name: names[id],
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(
                    this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].amount
                  )
                ),
                summary: null,
              });
            }
          }
          if (details.length > 0) {
            result.details.push({
              name: '預託金',
              details: details,
              total: details.reduce((a, v) => {
                return a + v.amount;
              }, 0),
            });
          }
        }
      }
      // 公租公課
      {
        let details = [];
        // 印紙税
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[5] == 1) {
          // 概算入力時
          if (this.investmentAnalytics.initialInvestment.isDetailStampDuty == 0) {
            if (!this.isEmpty(this.investmentAnalytics.initialInvestment.estimatedStampDuty)) {
              details.push({
                name: '印紙税',
                quantity: 1,
                unit: '式',
                amount: Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.estimatedStampDuty)
                ),
                summary: null,
              });
            }
          }
          // 詳細入力時
          else {
            const items = [
              '不動産売買',
              '工事請負契約',
              'コンサル契約',
              '設計監理契約',
              '金銭消費貸借契約',
              '賃貸借契約',
            ];
            let subDetails = [];
            for (let id = 0; id < items.length; id++) {
              let value = null;
              value = this.investmentAnalytics.initialInvestment.stampDuty[id];
              if (!this.isEmpty(value)) {
                subDetails.push({
                  name: items[id],
                  quantity: 1,
                  unit: '式',
                  amount: Math.floor(Number(value)),
                  summary: null,
                });
              }
            }
            if (subDetails.length > 0) {
              // 小計
              details.push({
                name: '印紙税',
                details: subDetails,
                total: subDetails.reduce((a, v) => {
                  return a + v.amount;
                }, 0),
              });
            }
          }
        }
        // 不動産取得税
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[6] == 1) {
          let land = this.realEstateAcquisitionTaxAmount(0); // 土地
          let building = 0;
          for (let id = 0; id < this.investmentAnalytics.existingBuildingCount; id++) {
            building += this.realEstateAcquisitionTaxAmount(1, id); // 既存建物
          }
          for (let id = 0; id < this.investmentAnalytics.newBuildingCount; id++) {
            building += this.realEstateAcquisitionTaxAmount(2, id); // 新築建物
          }
          building += this.realEstateAcquisitionTaxAmount(3); // 付属建物
          if (land + building > 0) {
            details.push({
              name: '不動産取得税',
              details: [
                {
                  name: '土地',
                  quantity: 1,
                  unit: '式',
                  amount: land,
                  summary: null,
                },
                {
                  name: '建物',
                  quantity: 1,
                  unit: '式',
                  amount: building,
                  summary: null,
                },
              ],
              total: land + building,
            });
          }
        }
        // 登録免許税
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[7] == 1) {
          let subDetails = [];
          // 所有権移転
          let total = this.registrationTaxAmount(0); // 土地
          for (let id = 0; id < this.investmentAnalytics.existingBuildingCount; id++) {
            total += this.registrationTaxAmount(1, id); // 既存建物
          }
          total += this.registrationTaxAmount(7); // 付属建物
          if (total > 0) {
            subDetails.push({
              name: '所有権移転',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
          }
          // 抵当権設定
          if (this.registrationTaxAmount(3) + this.registrationTaxAmount(4) > 0) {
            subDetails.push({
              name: '抵当権設定',
              quantity: 1,
              unit: '式',
              amount: this.registrationTaxAmount(3) + this.registrationTaxAmount(4),
              summary: null,
            });
          }
          // 借地権設定
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[11]
            )
          ) {
            subDetails.push({
              name: '借地権設定',
              quantity: 1,
              unit: '式',
              amount: this.registrationTaxAmount(5),
              summary: null,
            });
          }
          // 建物保存
          if (this.investmentAnalytics.newBuildingCount > 0) {
            let total = 0;
            for (let id = 0; id < this.investmentAnalytics.newBuildingCount; id++) {
              total += this.registrationTaxAmount(2, id);
            }
            if (total > 0) {
              subDetails.push({
                name: '建物保存',
                quantity: 1,
                unit: '式',
                amount: total,
                summary: null,
              });
            }
          }
          // 登記記録調査
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.registrationTax
                .numberOfRegisteredRecordsSurveyed
            )
          ) {
            subDetails.push({
              name: '登記記録調査',
              quantity: 1,
              unit: '式',
              amount: this.registrationTaxAmount(6),
              summary: null,
            });
          }
          // 小計
          details.push({
            name: '登録免許税',
            details: subDetails,
            total: subDetails.reduce((a, v) => {
              return a + v.amount;
            }, 0),
          });
        }
        if (this.propertyTaxTotalAll + this.cityPlanningTaxTotalAll > 0) {
          // 固都税清算金
          details.push({
            name: '固都税清算金',
            details: [
              {
                name: '固定資産税',
                quantity: 1,
                unit: '式',
                amount: this.propertyTaxTotalAll,
                summary: null,
              },
              {
                name: '都市計画税',
                quantity: 1,
                unit: '式',
                amount: this.cityPlanningTaxTotalAll,
                summary: null,
              },
            ],
            total: this.propertyTaxTotalAll + this.cityPlanningTaxTotalAll,
          });
        }
        // 小計
        result.details.push({
          name: '公租公課',
          details: details,
          total: details.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0),
        });
      }
      // 支払手数料
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[8] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.paymentFees.isDetail == 0) {
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.paymentFees.estimatedPaymentFee
            )
          ) {
            result.details.push({
              name: '支払手数料',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(
                  Number(this.investmentAnalytics.initialInvestment.paymentFees.estimatedPaymentFee)
                )
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          let details = [];
          // 司法書士
          {
            let subDetails = [];
            let items = [
              // name:項目名, index:インデックス
              { name: '建物保存登記', index: 2 },
              { name: '登記記録調査', index: 0 },
              { name: '議事録作成', index: 1 },
            ];
            // 「支払手数料->所有権移転登記」がある場合
            if (this.paymentFees(18) > 0) {
              subDetails.push({
                name: '所有権移転登記',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(18),
                summary: null,
              });
            }
            // 「支払手数料->抵当権設定登記」がある場合
            if (this.paymentFees(16) > 0) {
              subDetails.push({
                name: '抵当権設定登記',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(16),
                summary: null,
              });
            }
            // 「支払手数料->借地権設定登記」がある場合
            if (this.paymentFees(17) > 0) {
              subDetails.push({
                name: '借地権設定登記',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(17),
                summary: null,
              });
            }
            for (let id = 0; id < items.length; id++) {
              if (
                !this.isEmpty(
                  this.investmentAnalytics.initialInvestment.paymentFees.numberOfCases[
                    items[id].index
                  ]
                )
              ) {
                subDetails.push({
                  name: items[id].name,
                  quantity: 1,
                  unit: '式',
                  amount: this.paymentFees(items[id].index),
                  summary: null,
                });
              }
            }
            // 小計
            if (subDetails.length > 0) {
              details.push({
                name: '司法書士',
                details: subDetails,
                total: subDetails.reduce((a, v) => {
                  // return a + (isNaN(v.amount) ? 0 : v.amount);
                  return a + v.amount;
                }, 0),
              });
            }
          }
          // 土地家屋調査士
          {
            let subDetails = [];
            // 建物表示登記
            if (
              !this.isEmpty(
                this.registrationOfBuildingIndication()
                )
              ) {
              subDetails.push({
                name: '建物表示登記',
                quantity: 1,
                unit: '式',
                amount: this.registrationOfBuildingIndication(),
                summary: null,
              });
            }
            // 建物滅失登記
            if (
              !this.isEmpty(
                this.investmentAnalytics.initialInvestment.paymentFees.numberOfCases[13]
                )
              ) {
              subDetails.push({
                name: '建物滅失登記',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(13),
                summary: null,
              });
            }
            // 地目変更登記
            if (
              !this.isEmpty(
                this.investmentAnalytics.initialInvestment.paymentFees.numberOfCases[14]
              )
            ) {
              subDetails.push({
                name: '地目変更登記',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(14),
                summary: null,
              });
            }
            // 小計
            if (subDetails.length > 0) {
              let total = subDetails.reduce((a, v) => {
                return a + (isNaN(v.amount) ? 0 : v.amount);
              }, 0);
              details.push({
                name: '土地家屋調査士',
                details: subDetails,
                total: total,
              });
            }
          }
          // 不動産業者
          {
            let subDetails = [];
              // 売買仲介料
            if (
              !this.isEmpty(
                this.paymentFees(15)
              )
            ) {
              subDetails.push({
                name: '売買仲介料',
                quantity: 1,
                unit: '式',
                amount: this.paymentFees(15),
                summary: null,
              });
            }
            // 賃貸借仲介料
            if (
              !this.isEmpty(
                this.investmentAnalytics.initialInvestment.paymentFees.leaseBrokerageFee
              )
            ) {
              subDetails.push({
                name: '賃貸借仲介料',
                quantity: 1,
                unit: '式',
                amount: Number(
                  this.investmentAnalytics.initialInvestment.paymentFees.leaseBrokerageFee
                ),
                summary: null,
              });
            }
            // 小計
            details.push({
              name: '不動産業者',
              details: subDetails,
              total: subDetails.reduce((a, v) => {
                return a + (isNaN(v.amount) ? 0 : v.amount);
              }, 0),
            });
          }
           // 金融機関
          {
            const names = ['融資事務手数料', '固定金利手数料', '抵当権設定手数料'];
            let subDetails = [];
            if(this.paymentFees(19) + this.paymentFees(20) + this.paymentFees(21) > 0){
              names.forEach(n => {
                //19から金融機関手数料にしているため
                let id = names.indexOf(n) + 19;
                let amount = this.paymentFees(id);
                if(amount > 0){
                subDetails.push({
                  name: n,
                  quantity: 1,
                  unit: '式',
                  amount: amount,
                  summary: null,
                  });
                }
                });
            }

            // 小計
            details.push({
              name: '金融機関',
              details: subDetails,
              total: subDetails.reduce((a, v) => {
                return a + (isNaN(v.amount) ? 0 : v.amount);
              }, 0),
            });
          }
          // 消費税
          let total = details.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0); // ここまでの合計
          details.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 小計
          result.details.push({
            name: '支払手数料',
            details: details,
            total: this.sumTax(total),
          });
        }
      } else if (this.investmentAnalytics.initialInvestment.incidentalExpenses[7] == 1) {
        // 支払手数料がOFFだが登録免許税がONの場合(特殊例)
        //「登録免許税->抵当権設定」がある場合
        if (this.registrationTax(3) + this.registrationTax(4) > 0) {
          let details = [];
          let subDetails = [];
          subDetails.push({
            name: '抵当権設定登記',
            quantity: 1,
            unit: '式',
            amount: this.getUnitPrice('支払手数料', '抵当権設定登記'),
            summary: null,
          });
          //小計
          details.push({
            name: '司法書士',
            details: subDetails,
            total: subDetails[0].amount,
          });
          // 消費税
          details.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(subDetails[0].amount),
            summary: null,
          });
          // 小計
          result.details.push({
            name: '支払手数料',
            details: details,
            total: details.reduce((a, v) => {
              return a + (isNaN(v.amount) ? v.total : v.amount);
            }, 0),
          });
        }
      }
      // 事業開始前負担
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 0) {
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.preCareerCommitment.approximateValue
            )
          ) {
            result.details.push({
              name: '事業開始前負担',
              quantity: 1,
              unit: '式',
              amount: this.sumTax(
                Math.floor(
                  Number(
                    this.investmentAnalytics.initialInvestment.preCareerCommitment.approximateValue
                  )
                )
              ),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          // 賃借料
          {
            let details = [];
            // 事業開始前賃料
            let subDetails = [];
            if (this.preBusinessRent > 0) {
              const names = ['工事期間中', '募集/FR期間中', '立退期間中'];
              for (let id = 0; id < names.length; id++) {
                if (
                  Math.floor(
                    Number(
                      this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[id]
                    )
                  )
                ) {
                  subDetails.push({
                    name: names[id],
                    quantity: Math.floor(
                      Number(
                        this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[id]
                      )
                    ),
                    unit: 'ヶ月',
                    amount:
                      Math.floor(
                        Number(
                          this.investmentAnalytics.initialInvestment.preCareerCommitment.duration[
                            id
                          ]
                        )
                      ) * this.preBusinessRent,
                    summary: null,
                  });
                }
              }
              if (subDetails.length > 0) {
                details.push({
                  name: '事業開始前賃料',
                  details: subDetails,
                  total: subDetails.reduce((a, v) => {
                    return a + (isNaN(v.amount) ? 0 : v.amount);
                  }, 0),
                });
              }
            }
            // 事業開始前占用料
            const months = this.investmentAnalytics.initialInvestment.preCareerCommitment
              .duration[3];
            const fee = Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyOccupancyFee
              )
            );
            if (months > 0 && fee > 0) {
              subDetails = [
                {
                  name: '道・水路占用料',
                  quantity: months,
                  unit: 'ヶ月',
                  amount: months * fee,
                  summary: null,
                },
              ];
              details.push({
                name: '事業開始前占用料',
                details: subDetails,
                total: subDetails.reduce((a, v) => {
                  return a + v.amount;
                }, 0),
              });
            }
            if (details.length > 0) {
              // 小計
              result.details.push({
                name: '賃借料',
                details: details,
                total: details.reduce((a, v) => {
                  return a + (isNaN(v.amount) ? v.total : v.amount);
                }, 0),
              });
            }
          }
          // 支払利息
          if (this.interestExpenseTotal2 > 0) {
            const months = this.investmentAnalytics.initialInvestment.preCareerCommitment
              .duration[4];
            result.details.push({
              name: '支払利息',
              details: [
                {
                  name: '事業開始前利息',
                  details: [
                    {
                      name: '借入利息',
                      quantity: months,
                      unit: 'ヶ月',
                      amount: this.interestExpenseTotal2,
                      summary: null,
                    },
                  ],
                  total: this.interestExpenseTotal2,
                },
              ],
              total: this.interestExpenseTotal2,
            });
          }
        }
      }
      // 損害保険料
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[10] == 1) {
        let details = [];
        // 火災保険料
        if (
          !this.isEmpty(this.investmentAnalytics.initialInvestment.insuranceFees[0]) ||
          !this.isEmpty(this.investmentAnalytics.initialInvestment.insuranceFees[1])
        ) {
          details.push({
            name: '火災保険料',
            quantity: 5,
            unit: '年',
            amount:
              Number(this.investmentAnalytics.initialInvestment.insuranceFees[0]) +
              Number(this.investmentAnalytics.initialInvestment.insuranceFees[1]),
            summary: null,
          });
        }
        // 施設賠償責任保険料
        if (!this.isEmpty(this.investmentAnalytics.initialInvestment.insuranceFees[2])) {
          details.push({
            name: '施設賠償責任保険料',
            quantity: 1,
            unit: '年',
            amount: Number(this.investmentAnalytics.initialInvestment.insuranceFees[2]),
            summary: null,
          });
        }

        if (details.length > 0) {
          result.details.push({
            name: '損害保険料',
            details: details,
            total: details.reduce((a, v) => {
              return a + (isNaN(v.amount) ? v.total : v.amount);
            }, 0),
          });
        }
      }
      // その他
      if (this.investmentAnalytics.initialInvestment.incidentalExpenses[11] == 1) {
        // 概算入力時
        if (this.investmentAnalytics.initialInvestment.otherExpenses.isDetail == 0) {
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.estimatedOtherExpenses
            )
          ) {
            result.details.push({
              name: 'その他',
              quantity: 1,
              unit: '式',
              amount: Math.floor(
                Number(this.investmentAnalytics.initialInvestment.otherExpenses
                .estimatedOtherExpenses)),
              summary: null,
            });
          }
        }
        // 詳細入力時
        else {
          let details = [];
          // 祭祀費
          if (!this.isEmpty(this.investmentAnalytics.initialInvestment.otherExpenses.ritualCost)) {
            details.push({
              name: '祭祀費',
              quantity: 1,
              unit: '式',
              amount: Number(this.investmentAnalytics.initialInvestment.otherExpenses.ritualCost),
              summary: null,
            });
          }
          // 近隣対策費
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.neighborhoodWatchCosts.amount
            )
          ) {
            details.push({
              name: '近隣対策費',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.neighborhoodWatchCosts
                  .amount
              ),
              summary: null,
            });
          }
          // 立退料
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.estimatedEvictionFees
            )
          ) {
            details.push({
              name: '立退料',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.estimatedEvictionFees
              ),
              summary: null,
            });
          }
          // 営業補償
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.estimatedBusinessCompensation
            )
          ) {
            details.push({
              name: '営業補償',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses
                  .estimatedBusinessCompensation
              ),
              summary: null,
            });
          }
          // 広告費
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses
            )
          ) {
            details.push({
              name: '広告費',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses
              ),
              summary: null,
            });
          }
          // 特殊経費
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.estimatedSpecialExpenses
            )
          ) {
            details.push({
              name: '特殊経費',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.estimatedSpecialExpenses
              ),
              summary: null,
            });
          }
          // 諸雑費
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.miscellaneousExpenses
            )
          ) {
            details.push({
              name: '諸雑費',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.miscellaneousExpenses
              ),
              summary: null,
            });
          }
          // 予備費
          if (
            !this.isEmpty(
              this.investmentAnalytics.initialInvestment.otherExpenses.estimatedReserveFund
            )
          ) {
            details.push({
              name: '予備費',
              quantity: 1,
              unit: '式',
              amount: Number(
                this.investmentAnalytics.initialInvestment.otherExpenses.estimatedReserveFund
              ),
              summary: null,
            });
          }
          // 小計
          if (details.length > 0) {
            result.details.push({
              name: 'その他',
              details: details,
              total: details.reduce((a, v) => {
                return a + (isNaN(v.amount) ? v.total : v.amount);
              }, 0),
            });
          }
        }
      }
      // 合計
      result.total = result.details.reduce((a, v) => {
        return a + (isNaN(v.amount) ? v.total : v.amount);
      }, 0);
      return result;
    },
  },
  methods: {
    // 「住宅」の戸数を集計
    numberOfHouses: function() {
      let count = 0;
      // 既存建物分
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        if (
          ['居宅'].some(
            item =>
              item == this.investmentAnalytics.initialInvestment.propertyDescription[i + 1].kind
          )
        ) {
          count += Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[i + 1].quantity)
          );
        }
        if (
          ['共同住宅'].some(
            item =>
              item == this.investmentAnalytics.initialInvestment.propertyDescription[i + 1].kind
          )
        ) {
          count += Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[i + 1].quantity)
          );
        }
      }
      // 新築建物分
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        if (
          ['居宅'].some(
            item =>
              item == this.investmentAnalytics.initialInvestment.propertyDescription[i + 11].kind
          )
        ) {
          count += Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[i + 11].quantity)
          );
        }
        if (
          ['共同住宅'].some(
            item =>
              item == this.investmentAnalytics.initialInvestment.propertyDescription[i + 11].kind
          )
        ) {
          count += Math.floor(
            Number(this.investmentAnalytics.initialInvestment.propertyDescription[i + 11].quantity)
          );
        }
      }
      return count;
    },
    // 居宅＆共同住宅の戸数を集計
    allNumberOfHouses: function() {
      let count = 0;
      // 既存建物分
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
          i + 1
        ];
        if (propertyDescription.kind == '居宅' || propertyDescription.kind == '共同住宅') {
          count += Math.floor(Number(propertyDescription.quantity));
        }
      }
      // 新築建物分
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        let propertyDescription = this.investmentAnalytics.initialInvestment.propertyDescription[
          i + 11
        ];
        if (propertyDescription.kind == '居宅' || propertyDescription.kind == '共同住宅') {
          count += Math.floor(Number(propertyDescription.quantity));
        }
      }
      return count;
    },
    // 計算(付帯費用)
    calcIncidentalExpenses: function() {
      return this.calcIncidentalExpensesComputed;
    },
    // 建物表示登記
    registrationOfBuildingIndication: function() {
      let total = 0;
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        total += this.paymentFees(3 + i);
      }
      return total;
    },
  },
};
